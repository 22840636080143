import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import styled from "styled-components";
import Webcam from "react-webcam";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import SendIcon from "@material-ui/icons/Send";
import AttachmentIcon from "@material-ui/icons/Attachment";
import config from "../config.json";
import Image from "./Utils/image";

const Container = styled.div`
  background-color: black;
  color: white;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Success = styled.div`
  position: absolute;
  bottom: 2vh;
  width: 80vw;
  font-size: 20px;
  font-family: 'reg';
  margin: 10vw;
`;

const Top = styled.section`
  text-align: right;
`;
const Center = styled.section`
  flex-grow: 1;
  display: inherit;
  vertical-align: middle;
`;
const Bottom = styled.section`
  display: flex;
  flex-direction: row;
`;

const ImageBooth = styled.img`
  border: 1px solid white;
  text-align: center;
  bottom: 5vh;
  position: absolute;
  width: 80vw;
  height: 80vw;
  margin-left: 10vw;
  object-fit: cover;
`;

const DropZone = styled.div`
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  font-family: "reg";
  font-size: 4vw;
  bottom: 25vh;
  position: absolute;
  width: 80vw;
  margin-left: 10vw;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
`;

const CaptureButton = styled.button`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 25px;
  margin: auto;
`;

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};

export default function PhotoBooth({ uploadCallback }) {
  const { userId } = useParams();
  if (userId) {
    //TODO: does it make sense to set it to the state
  }
  const { sendMessage, lastMessage, readyState } = useWebSocket(config.wss);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [uploaded, setUploaded] = useState(false);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    console.log(imageSrc);
  }, [webcamRef, setImgSrc]);
  const upload = useCallback(
    (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        console.log(reader.result);
        setImgSrc(reader.result);
        uploadCallback(reader.result, userId);
        setTimeout(() => {
          setUploaded(true);
        }, 3000);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    [setImgSrc]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => {
        upload(file, userId);
        // console.log(URL.createObjectURL(file));
      });
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Container>
      <div className="upload__computer">
        Please upload your picture from a phone.
      </div>
      <div className="mobile__plants">
        <Image id="d589e744-92f4-4617-b8c6-7710fde86d98" />
      </div>
      <h1 className="mobile__title">
        The Garden
        <br />
        of Tangled Data
      </h1>
      {uploaded && (
        <Success>
          Thanks for contributing, your seed is on its way to the Tangled Garden
          ecosystem. It will be there in a few seconds.
        </Success>
      )}
      {!uploaded && (
        <>
          {!imgSrc && (
            <>
              {/* <Top>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    if (facingMode == FACING_MODE_ENVIRONMENT) {
                      setFacingMode(FACING_MODE_USER);
                    } else {
                      setFacingMode(FACING_MODE_ENVIRONMENT);
                    }
                  }}
                >
                  <FlipCameraAndroidIcon></FlipCameraAndroidIcon>
                </Button>
              </Top> */}
              {/* <Center>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    ...videoConstraints,
                    facingMode,
                  }}
                  style={{ width: "100%" }}
                />
              </Center> */}
              <DropZone {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Upload a picture to visit the garden</p>
              </DropZone>
              {/* <Bottom>
                <Button variant="contained" component="label" color="primary">
                  <AttachmentIcon></AttachmentIcon>
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={({ target }) => {
                      //   console.log(target.files);
                      upload(target.files[0]);
                    }}
                  />
                </Button>
                <Button
                  onClick={capture}
                  color="primary"
                  variant="contained"
                  style={{ flexGrow: 1 }}
                >
                  Upload a picture to visit the garden
                </Button>
              </Bottom> */}
            </>
          )}

          {imgSrc && (
            <>
              <Center>
                <ImageBooth src={imgSrc}></ImageBooth>
              </Center>
              {/* <Bottom>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setImgSrc(null);
                  }}
                >
                  retake
                </Button>
                <Button
                  style={{ flexGrow: 1 }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    console.log(userId);
                    uploadCallback(imgSrc, userId);
                    const message = {
                      type: "PHOTOUPLOADED",
                      payload: { userId },
                    };
                    sendMessage(JSON.stringify(message));
                    setUploaded(true);
                    //   console.log("TODO: upload to backend", userId, imgSrc);
                  }}
                >
                  <SendIcon></SendIcon>
                </Button>
              </Bottom> */}
            </>
          )}
        </>
      )}
    </Container>
  );
}
