import React, { useEffect, useState } from "react";
import styled from "styled-components";
import config from "../config";
import { format, differenceInMilliseconds } from "date-fns";
import { useStore } from "../store";
import Button from "@material-ui/core/Button";
import jszip from "jszip";
import { saveAs } from "file-saver";
import { getSeedByUser, getSeedsByUser, getAssetUrl } from "../api";

const zip = new jszip();

const Container = styled.div`
  border: 2px solid #73ad21;
  padding: 20px;
  background-color: black;
  color: #33ff33;
`;

export default function Downloader() {
  //   const userId = useStore((state) => state.userId);
  const userId = "B9gy";

  //   fetches user's images
  //   gets called only once
  // TODO: store in component state
  useEffect(() => {
    getSeedsByUser(userId).then((data) => {
      data.forEach((item) => {
        console.log(getAssetUrl(item.seeds_id.id, 600, 600));
        const content =
          "data:image/gif;base64,R0lGODdhEAAQAMwAAPj7+FmhUYjNfGuxYYDJdYTIeanOpT+DOTuANXi/bGOrWj6CONzv2sPjv2CmV1unU4zPgISg6DJnJ3ImTh8Mtbs00aNP1CZSGy0YqLEn47RgXW8amasW7XWsmmvX2iuXiwAAAAAEAAQAAAFVyAgjmRpnihqGCkpDQPbGkNUOFk6DZqgHCNGg2T4QAQBoIiRSAwBE4VA4FACKgkB5NGReASFZEmxsQ0whPDi9BiACYQAInXhwOUtgCUQoORFCGt/g4QAIQA7";
      });
    });
  }, []);

  return (
    <>
      <Container>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const folder = zip.folder("collection");
            // folder.file(`test.gif`, content);
            folder
              .generateAsync({ type: "blob" })
              .then((content) => saveAs(content, "files"));

            // console.log("TODO: download files");
          }}
        >
          TODO: download
        </Button>
      </Container>
    </>
  );
}
