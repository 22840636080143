import React, { useState } from "react";
import styled from "styled-components";
import config from "../config.json";
import TextField from "@material-ui/core/TextField";

const Container = styled.div`
  background-color: lightskyblue;
  color: darkgoldenrod;
`;

export default function () {
  const [userId, setUserId] = useState();
  return (
    <Container>
      <h1>404</h1>
      did you get lost in the garden? seems you took the wrong path. enter your
      user id, in case you remember:{" "}
      <TextField
        value={userId}
        onChange={(e) => {
          setUserId(e.target.value);
        }}
      ></TextField>
      <ul>
        <li>
          go to main page:{" "}
          <a href={`${config.url}/${userId ? userId : ""}`}>here</a>
        </li>
        <li>
          go to upload page:{" "}
          <a href={`${config.uploadUrl}/${userId ? userId : ""}`}>here</a>
        </li>
      </ul>
    </Container>
  );
}
